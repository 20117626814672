<template>
    <div class="success-payment-page">
        <div class="wrapper">
            <div class="success-logo swirl-in-fwd ">
                <img :src="success_logo" alt="">
            </div>
            <div class="content">
                <h1 style="color: #333;">העסקה בוצעה בהצלחה</h1>
                <button @click="handle_back" class="back-btn">חזרה לאתר</button>
            </div>
        </div>
    </div>
</template>

<script>
    import success_logo from '../../../assets/images/success.png'
    import router from '../../../router';
    export default {
        setup(){

            const handle_back = () => {
                router.replace({name:"agentportal"})
            }
            return{
                success_logo,
                handle_back
            }
        }
    }
</script>

<style scoped>
    .success-payment-page{
        background:#fff !important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .success-payment-page > .wrapper{
        width: 100%;
        max-width: 500px;
        height: 90%;
        border-radius: 10px;
    }
    .success-logo{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .success-logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .success-payment-page > .wrapper > .content{
        width: 100%;
        height: calc(100% - 200px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .success-payment-page > .wrapper > .content > .back-btn{
        width: 80%;
        height: 70px;
        border: 0;
        padding: 20px 10px;
        margin-top: 10px;
        background: var(--success);
        color: #fff;
        border-radius: 10px;
        cursor: pointer;
        user-select: none;
    }

    .swirl-in-fwd {
	    -webkit-animation: swirl-in-fwd 0.6s ease-out both;
	    animation: swirl-in-fwd 0.6s ease-out both;
    }




    @-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
                transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
                transform: rotate(0) scale(1);
        opacity: 1;
    }
    }
    @keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
                transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
                transform: rotate(0) scale(1);
        opacity: 1;
    }
    }


</style>